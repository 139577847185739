.dashboard{
    .card{
        position: relative;
        box-shadow:  0 0 3px #dedede7d;
        cursor: pointer;
        // color: #fff;
        .card-body{
            padding: 2.5rem 1.8rem;
        }
        h2,span {
            color: #5B6670;
            z-index: 2;
            position: relative;
        }

        img{
            position: absolute;
            top: 50%;
            color: #5B6670;
            transform: translate(0, -50%);
            font-size: 50px;
            z-index: 2;
        }
    }
}
.en .dashboard img{
    right: 8px;
    left: auto;
}
.ar .dashboard img{
    left: 8px;
    right: auto;
}
.skeleton-loader {
    .loader-header {
        height: 30px;
        width: 60%;
        background-color: #dedede8f;
        margin-bottom: 10px;
    }
    .loader-content {
        height: 15px;
        width: 100%;
        background-color: #dedede8f;
        margin-top: 16px;
    }
}
  