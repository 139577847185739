// @keyframes loader_5191 {
//     from {
//       opacity: 0;
//     }
  
//     to {
//       opacity: 1;
//     }
//   }
  
//   .square {
//     background: var(--primary);
//     width: 10px;
//     height: 10px;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     margin-top: -5px;
//     margin-left: -5px;
//   }
  
//   #sq1 {
//     margin-top: -25px;
//     margin-left: -25px;
//     animation: loader_5191 675ms ease-in-out 0s infinite alternate;
//   }
  
//   #sq2 {
//     margin-top: -25px;
//     animation: loader_5191 675ms ease-in-out 75ms infinite alternate;
//   }
  
//   #sq3 {
//     margin-top: -25px;
//     margin-left: 15px;
//     animation: loader_5191 675ms ease-in-out 150ms infinite;
//   }
  
//   #sq4 {
//     margin-left: -25px;
//     animation: loader_5191 675ms ease-in-out 225ms infinite;
//   }
  
//   #sq5 {
//     animation: loader_5191 675ms ease-in-out 300ms infinite;
//   }
  
//   #sq6 {
//     margin-left: 15px;
//     animation: loader_5191 675ms ease-in-out 375ms infinite;
//   }
  
//   #sq7 {
//     margin-top: 15px;
//     margin-left: -25px;
//     animation: loader_5191 675ms ease-in-out 450ms infinite;
//   }
  
//   #sq8 {
//     margin-top: 15px;
//     animation: loader_5191 675ms ease-in-out 525ms infinite;
//   }
  
//   #sq9 {
//     margin-top: 15px;
//     margin-left: 15px;
//     animation: loader_5191 675ms ease-in-out 600ms infinite;
//   }
/* HTML: <div class="loader"></div> */
.loader {
  width: 75px;
  aspect-ratio: 1; 
  display: grid;
  margin: auto;
  display: flex;
  align-items: center;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  width: 35px;
  aspect-ratio: 1;
  box-shadow: 0 0 0 3px var(--primary) inset;
  filter: drop-shadow(40px 40px 0 var(--primary));
  animation: l8 2s infinite alternate;
}
.loader:after {
  margin: 0 0 0 auto; 
  filter: drop-shadow(-40px 40px 0 var(--primary));
  animation-delay: -1s;
}
@keyframes l8 {
  0%,10%   {border-radius:0}
  30%,40%  {border-radius:50% 0}
  60%,70%  {border-radius:50%}
  90%,100% {border-radius:0 50%}
}